import LoginForm from 'features/auth/components/LoginForm';
import styled from 'styled-components';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import Logo from 'components/Images/Logo';
import React from 'react';

const PageContainer = styled.div`
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 25px 25px;
  position: fixed;

  @media (min-width: 400px) {
    justify-content: center;
  }
`;
const Container = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border-radius: 10px;
  padding: 24px;

  @media (min-width: 768px) {
    width: 400px;
  }
`;

const FormHeader = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const LogoContainer = styled.div`
  img {
    width: 100px;
  }

  @media (min-width: 500px) {
    img {
      width: 175px;
    }
  }
`;

const TitleContainer = styled.div`
  p {
    text-align: center;
    font-size: 16px;
  }

  p:nth-child(2) {
    font-weight: 600;
  }
`;
/**
 * Renders a view with a welcome message and Login
 * Form. Users are able to sign in with their credentials
 * @returns
 */
function Login() {
  return (
    <PageContainer>
      <Container>
        <FormHeader>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <TitleContainer>
            <ParagraphText marginTop={10}>
              Sign in or sign up to apply for
            </ParagraphText>
            <ParagraphText>
              Summer Coding Leadership Academy (SCLA) 2025
            </ParagraphText>
          </TitleContainer>
        </FormHeader>

        <LoginForm />
      </Container>
    </PageContainer>
  );
}

export default Login;
