import SCHOOL_LIST from './schools';

/**
 * Picker options for txter cohort year
 */
export const PICKER_PROGRAM_YEAR = [
  {
    value: '2016',
    label: '2016',
    role: 'Program Year',
  },
  {
    value: '2017',
    label: '2017',
    role: 'Program Year',
  },
  {
    value: '2018',
    label: '2018',
    role: 'Program Year',
  },
  {
    value: '2019',
    label: '2019',
    role: 'Program Year',
  },
  {
    value: '2020',
    label: '2020',
    role: 'Program Year',
  },
  {
    value: '2021',
    label: '2021',
    role: 'Program Year',
  },
  {
    value: '2022',
    label: '2022',
    role: 'Program Year',
  },
  {
    value: '2023',
    label: '2023',
    role: 'Program Year',
  },
];

/**
 * Picker options for grade
 */
export const PICKER_GRADE = [
  {
    value: '8',
    label: '8th Grade',
    role: 'Grade',
  },
  {
    value: '9',
    label: '9th Grade',
    role: 'Grade',
  },
  {
    value: '10',
    label: '10th Grade',
    role: 'Grade',
  },
  {
    value: '11',
    label: '11th Grade',
    role: 'Grade',
  },
  {
    value: '12',
    label: '12th Grade',
    role: 'Grade',
  },
];

export const PICKER_YES_NO = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const PICKER_CONTACT_METHOD = [
  {
    value: 'phone',
    label: 'Phone Call',
    role: 'Contact Method',
  },
  {
    value: 'sms',
    label: 'Text Message(SMS)',
    role: 'Contact Method',
  },
  {
    value: 'slack',
    label: 'Slack',
    role: 'Contact Method',
  },
  {
    value: 'instagram',
    label: 'Instagram',
    role: 'Contact Method',
  },
  {
    value: 'email',
    label: 'Email',
    role: 'Contact Method',
  },
];

/**
 * Picker options for ethnicity
 */
export const PICKER_ETHNICITY = [
  {
    value: 'African American',
    label: 'African American',
  },
  {
    value: 'Latino/Hispanic',
    label: 'Latino/Hispanic',
  },
  {
    value: 'Asian',
    label: 'Asian',
  },
  {
    value: 'Caucasian',
    label: 'Caucasian',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

/**
 * Picker constants for gender
 */
export const PICKER_GENDER = [
  {
    value: 'male',
    label: 'male',
    role: 'gender',
  },
  {
    value: 'female',
    label: 'female',
    role: 'gender',
  },
  {
    value: 'unspecified',
    label: 'unspecified',
    role: 'gender',
  },
];

export const PICKER_MARITAL_STATUS = [
  {
    value: 'married',
    label: 'Married',
    role: '',
  },
  {
    value: 'Separated',
    label: 'Separated',
    role: '',
  },
  {
    value: 'divorced',
    label: 'Divorced',
    role: '',
  },
  {
    value: 'widowed',
    label: 'Widowed',
    role: '',
  },
  {
    value: 'single',
    label: 'Single',
    role: '',
  },
];

export const PICKER_EMPLOYMENT_STATUS = [
  {
    value: 'employed',
    label: 'Employed',
    role: '',
  },
  {
    value: 'unemployed',
    label: 'Unemployed',
    role: '',
  },
  {
    value: 'retired',
    label: 'Retired',
    role: '',
  },
  {
    value: 'deceased',
    label: 'Deceased',
    role: '',
  },
  {
    value: 'no-contact',
    label: 'No Contact',
    role: '',
  },
];

export const PICKER_ALL_STATES = [
  { label: 'Alabama', value: 'alabama', role: '' },
  { label: 'Alaska', value: 'alaska', role: '' },
  { label: 'Arizona', value: 'arizona', role: '' },
  { label: 'Arkansas', value: 'arkansas', role: '' },
  { label: 'California', value: 'california', role: '' },
  { label: 'Colorado', value: 'colorado', role: '' },
  { label: 'Connecticut', value: 'connecticut', role: '' },
  { label: 'Delaware', value: 'delaware', role: '' },
  { label: 'Florida', value: 'florida', role: '' },
  { label: 'Georgia', value: 'georgia', role: '' },
  { label: 'Hawaii', value: 'hawaii', role: '' },
  { label: 'Idaho', value: 'idaho', role: '' },
  { label: 'Illinois', value: 'illinois', role: '' },
  { label: 'Indiana', value: 'indiana', role: '' },
  { label: 'Iowa', value: 'iowa', role: '' },
  { label: 'Kansas', value: 'kansas', role: '' },
  { label: 'Kentucky', value: 'kentucky', role: '' },
  { label: 'Louisiana', value: 'louisiana', role: '' },
  { label: 'Maine', value: 'maine', role: '' },
  { label: 'Maryland', value: 'maryland', role: '' },
  { label: 'Massachusetts', value: 'massachusetts', role: '' },
  { label: 'Michigan', value: 'michigan', role: '' },
  { label: 'Minnesota', value: 'minnesota', role: '' },
  { label: 'Mississippi', value: 'mississippi', role: '' },
  { label: 'Missouri', value: 'missouri', role: '' },
  { label: 'Montana', value: 'montana', role: '' },
  { label: 'Nebraska', value: 'nebraska', role: '' },
  { label: 'Nevada', value: 'nevada', role: '' },
  { label: 'New Hampshire', value: 'new-hampshire', role: '' },
  { label: 'New Jersey', value: 'new-jersey', role: '' },
  { label: 'New Mexico', value: 'new-mexico', role: '' },
  { label: 'New York', value: 'new-york', role: '' },
  { label: 'North Carolina', value: 'north-carolina', role: '' },
  { label: 'North Dakota', value: 'north-dakota', role: '' },
  { label: 'Ohio', value: 'ohio', role: '' },
  { label: 'Oklahoma', value: 'oklahoma', role: '' },
  { label: 'Oregon', value: 'oregon', role: '' },
  { label: 'Pennsylvania', value: 'pennsylvania', role: '' },
  { label: 'Rhode Island', value: 'rhode-island', role: '' },
  { label: 'South Carolina', value: 'south-carolina', role: '' },
  { label: 'South Dakota', value: 'south-dakota', role: '' },
  { label: 'Tennessee', value: 'tennessee', role: '' },
  { label: 'Texas', value: 'texas', role: '' },
  { label: 'Utah', value: 'utah', role: '' },
  { label: 'Vermont', value: 'vermont', role: '' },
  { label: 'Virginia', value: 'virginia', role: '' },
  { label: 'Washington', value: 'washington', role: '' },
  { label: 'West Virginia', value: 'west-virginia', role: '' },
  { label: 'Wisconsin', value: 'wisconsin', role: '' },
  { label: 'Wyoming', value: 'wyoming', role: '' },
];

export const PICKER_LANGUAGES = [
  { label: 'English', value: 'english', role: '' },
  { label: 'Spanish', value: 'spanish', role: '' },
  { label: 'Mandarin Chinese', value: 'mandarin-chinese', role: '' },
  { label: 'Korean', value: 'korean', role: '' },
  { label: 'Japanese', value: 'japanese', role: '' },
  { label: 'Other', value: 'other', role: '' },
];

export const PICKER_SCHOOLS = SCHOOL_LIST.map(school => ({
  label: school,
  value: school,
}));

export const PICKER_SCLA_YEARS = [
  {
    label: '2019',
    value: '2019',
  },

  {
    label: '2020',
    value: '2020',
  },

  {
    label: '2021',
    value: '2021',
  },

  {
    label: '2022',
    value: '2022',
  },

  {
    label: '2023',
    value: '2023',
  },
];

export const PICKER_SEALS_MOTIVATION = [
  {
    label: 'To be a bad-ass SEAL',
    value: 'seal',
  },

  {
    label: 'To be a better leader',
    value: 'leader',
  },

  {
    label: 'To be a better developer',
    value: 'developer',
  },

  {
    label: 'To be better at business development',
    value: 'business',
  },

  {
    label: 'To learn Artificial Intelligence',
    value: 'ai',
  },
];

export const PICKER_SEALS_CREED = [
  {
    label: 'I want to improve on leading by example.',
    value: 'I want to improve on leading by example.',
  },
  {
    label:
      'I want to improve on being honest and not lying, cheating, or stealing.',
    value:
      'I want to improve on being honest and not lying, cheating, or stealing.',
  },
  {
    label: 'I want to improve on thinking outside the box.',
    value: 'I want to improve on thinking outside the box.',
  },
  {
    label: 'I want to improve on helping my neighbor and community.',
    value: 'I want to improve on helping my neighbor and community.',
  },
  {
    label:
      'I want to improve on understanding that leadership is action, not just a position.',
    value:
      'I want to improve on understanding that leadership is action, not just a position.',
  },
  {
    label:
      'I want to improve on being a role model to young males in my community.',
    value:
      'I want to improve on being a role model to young males in my community.',
  },
  {
    label:
      'I want to improve on my belief that nothing is hard, just a lot of work.',
    value:
      'I want to improve on my belief that nothing is hard, just a lot of work.',
  },
  {
    label:
      'I want to improve on striving to know myself better and seeking self-improvement.',
    value:
      'I want to improve on striving to know myself better and seeking self-improvement.',
  },
  {
    label: 'I want to improve on the habit of doing more than asked for.',
    value: 'I want to improve on the habit of doing more than asked for.',
  },
  {
    label:
      'I want to improve on striving to strengthen my mind, body, and soul.',
    value:
      'I want to improve on striving to strengthen my mind, body, and soul.',
  },
];

export const PICKER_LETTER_GRADE = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'F', label: 'F' },
];

export const PICKER_SEALS_HELP_PROCESS = [
  {
    label: 'I ask for help',
    value: 'ask-for-help',
  },

  {
    label: 'I research using Google.',
    value: 'research',
  },

  {
    label: 'I keep trying until I figure it out.',
    value: 'keep-trying',
  },

  {
    label: "A lot of time I don't know what to do.",
    value: 'idk',
  },
];

export const PICKER_SEALS_DOING_MORE_INCENTIVE = [
  {
    label: 'Money',
    value: 'money',
  },

  {
    label: 'Mentors',
    value: 'mentorship',
  },

  {
    label: 'Career',
    value: 'career',
  },

  {
    label: 'Family',
    value: 'family',
  },

  {
    label: 'Fame/Status',
    value: 'fame',
  },

  {
    label: 'Knowledge',
    value: 'knowledge',
  },

  {
    label: 'My Faith/Religion',
    value: 'faith',
  },
];

export const PICKER_SCLA_HEAR_ABOUT_US = [
  {
    label: 'Recommended by TXT Student',
    value: 'Recommended by TXT Student',
  },

  {
    label: 'Social Media',
    value: 'Social Media',
  },

  {
    label: 'TXT Website',
    value: 'TXT Website',
  },

  {
    label: 'School Presentation',
    value: 'School Presentation',
  },

  {
    label: 'Other',
    value: 'other',
  },
];


export const PICKER_SCLA_TRANSPORTATION = [
  {
    label: 'Car',
    value: 'car',
  },

  {
    label: 'Bus',
    value: 'bus',
  },

  {
    label: 'Train',
    value: 'train',
  },

  {
    label: 'Walk',
    value: 'walk',
  },
];

export const PICKER_SCLA_SALARY = [
  {
    label: '$0-$20,000',
    value: '$0-$20,000',
  },

  {
    label: '$21,000-$40,000',
    value: '$21,000-$40,000',
  },

  {
    label: '$41,000-$80,000',
    value: '$41,000-$80,000',
  },

  {
    label: '$81,000+',
    value: '$81,000+',
  },
];

export const PICKER_SCLA_CAREER = [
  {
    label: 'Technology',
    value: 'technology',
  },

  {
    label: 'Design',
    value: 'design',
  },

  {
    label: 'Business',
    value: 'business',
  },

  {
    label: 'Entrepreneurship',
    value: 'entrepreneurship',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PICKER_SCLA_CHARACTER = [
  { label: 'Knowledgeable', value: 'knowledgeable' },
  { label: 'Curious', value: 'curious' },
  { label: 'Non-Conformist', value: 'non-conformist' },
  { label: 'Compassionate', value: 'compassionate' },
  { label: 'Kind', value: 'kind' },
  { label: 'Determined', value: 'determined' },
  { label: 'Resilient', value: 'resilient' },
  { label: 'Confident', value: 'confident' },
  { label: 'People-Pleaser', value: 'people-pleaser' },
  { label: 'Over-Achiever', value: 'over-achiever' },
  { label: 'Perfectionist', value: 'perfectionist' },
  { label: 'Popular', value: 'popular' },
  { label: 'Leader', value: 'leader' },
];
