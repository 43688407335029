const RAW_SCHOOL_LIST = [
  '32nd Street / USC Performing Arts Magnet',
  'Abraham Lincoln High School',
  'Academy of Arts and Technology High School',
  'Academy of Medical and Public Service',
  'Academy of Science and Technology',
  'Academy of the Canyons',
  'Adams High School',
  'Adams MS',
  'Advanced Education Academy',
  'AGBU Manoogian-Demirdjian School',
  'AGBU Vatche and Tamar Manoukian High School',
  'Agoura High School',
  'Agvania High School',
  'Alain Leroy Locke College Preparatory Academy',
  'Alexander Hamilton High School',
  'Alhambra High School',
  'Alliance Alice M. Baxter College-Ready High School',
  'Alliance Burton Tech',
  'Alliance College Ready Middle 4',
  'Alliance College Ready Middle 8',
  'Alliance Collins Family College Ready HS',
  'Alliance Gertz Wressler High School',
  'Alliance Health Services Academy High School',
  'Alliance Kory Hunter Middle',
  'Alliance Leichtman Levine Family Foundation Environmental Science High School',
  'Alliance Marc and Eva math and science school',
  'Alliance Margaret M. Bloomfield High School',
  'Alliance Middle 12',
  'Alliance Morgan McKinzie High School',
  "Alliance Ouchi-O'Donovan 6-12 Complex",
  'Alliance Patti & Peter Neuwirth Leadership Academy',
  'Alliance Renee & Meyer Luskin College-Ready Academy',
  'Alliance Skirball Middle',
  'Alliance Ted K Tajima',
  'Alliance Tennenbaum Family Technology High School',
  'Alliance Virgil Roberts Leadership Academy',
  'Alondra Middle School',
  'Alverno High School',
  'American Academy',
  'American University Preparatory School',
  'Anderson W. Clark Magnet High School',
  'Animo City of Champions High School',
  'Animo Compton',
  'Animo De La Hoya',
  'Animo Ellen Ochoa Charter Middle School',
  'Animo Florence Firestone Middle School',
  'Animo Inglewood',
  'Animo Inglewood Charter High School',
  'Animo Jackie Robinson Charter High School',
  'Animo James B. Taylor Charter Middle School',
  'Animo Jefferson Charter Middle School',
  'Animo Leadership Charter High School',
  'Animo Legacy Charter Middle School',
  'Animo Locke Technology High School',
  'Los Angeles',
  'Animo Mae Jemison Charter Middle School',
  'Animo Pat Brown Charter High School',
  'Animo Pat Brown Charter Middle School',
  'Animo Ralph Bunche Charter High School',
  'Animo South Los Angeles Charter High School',
  'Animo Venice Charter High School',
  'Animo Venice Charter Middle School',
  'Animo Watts College Preparatory Academy',
  'Animo Westside Charter Middle School',
  'Antelope Valley High School',
  'Arcadia High School',
  'Archer School for Girls',
  'Arleta High School',
  'Arlington Heights Middle School',
  'Armenian Mesrobian School',
  'Arroyo High School',
  'Artesia High School',
  'Aspire Ollin University Prep',
  'Aspire Pacific Academy',
  'Assurance Learning Academy',
  'Assurance Learning Academy (charter)',
  'Los Angeles',
  'Assurance Learning Academy (charter)',
  'Wilmington',
  'Augustus F. Hawkins High School',
  'Augustus Hawkins High School A',
  'Augustus Hawkins High School B',
  'Augustus Hawkins High School C',
  'AV Academy High School',
  'Azusa High School',
  'Baker Middle School',
  'Baldwin Park High School',
  'Barton Hill Middle School',
  'Bassett High School',
  'Bell Gardens High School',
  'Bell High School',
  'Bellflower High School',
  'Belmont High School',
  'Benjamin Franklin High School',
  'Berendo Middle School',
  'Bethel Christian High School',
  'Bethune Middle School',
  'Beverly Hills High School',
  'Birmingham Community Charter High School',
  'Birmingham Community Charter High School (Lake Balboa',
  'LA)',
  'Birmingham High School',
  'Bishop Alemany High School',
  'Bishop Amat Memorial High School',
  'Bishop Conaty-Our Lady of Loretto High School',
  'Bishop Montgomery High School',
  'Bishop Mora Salesian High School',
  'Blair International Baccalaureate School',
  'Bonita High School',
  'Booth High School',
  'Los Angeles',
  'Brentwood School',
  'Bridges Academy',
  'Buckley School',
  'Burbank High School',
  'Cabrillo High School',
  'Calabasas High School',
  'California Academy of Math and Science',
  'California High School',
  'California School of the Arts',
  'Campbell Hall School',
  'Canoga Park High School',
  'Cantwell-Sacred Heart of Mary High School',
  'Canyon High School',
  'Carnegie Middle School',
  'Carson High School',
  'Carter-Walters Preparatory School of the Arts',
  'San Dimas',
  'Castaic High School',
  'Castro Valley High School',
  'Cathedral High School',
  'Centennial High School',
  'Cerritos High School',
  'CÈsar Ch·vez High School',
  'Cesar Chavez Middle School',
  'Chadwick School',
  'Chaminade College Preparatory School',
  'Charles Drew Middle School',
  'Charter Oak High School',
  'Chatsworth High School',
  'Chavez Learning Academies',
  'Citizens Of The World Charter School on Virgil Middle School Campus',
  'City Honors High School',
  'City Honors International Prep',
  'Claremont High School',
  'Cleveland High School',
  'Cleveland Humanities Magnet',
  'Cleveland Humanities Magnet School',
  'College-Ready Academy High School #4',
  'College-Ready Academy High School #6',
  'College-Ready Math-Science School',
  'Collegiate Charter of Los Angeles',
  'Columbus High School',
  'Community Charter Middle School',
  'Compton Early College',
  'Compton High School',
  'Computer Science Virtual Academy (LAUSD)',
  'Constitution Avenue High School',
  'Covina High School',
  'Crenshaw High School',
  'Crescenta Valley High School',
  'Crespi Carmelite High School',
  'Crest View Academy',
  'Crittenton High School',
  'Los Angeles',
  'Crossroads School',
  'Crossroads School for Arts and Sciences',
  'Crozier Middle School',
  'Culver City High School',
  'Curtis Middle School',
  'Da Vinci Communications High School',
  'Daily High School',
  'Damien High School',
  'Daniel Webster Middle School',
  'Del Mar High School',
  'Delphi Academy of Los Angeles',
  'Desert Christian High School',
  'Desert Sands Charter High School',
  'Garden Grove',
  'Desert Sands Charter High School',
  'Long Beach',
  'Desert Sands Charter High School',
  'Norwalk',
  'Desert Sands Charter High School',
  'Santa Ana',
  'Desert Sands Charter School',
  'Lancaster',
  'Desert Winds Continuation High School',
  'Diamond Bar High School',
  'Diamond Ranch High School',
  'Diego Rivera Learning Complex',
  'Dominguez High School',
  'Dominguez HS',
  'Don Bosco Technical Institute',
  'Dorsey High School',
  'Downey High School',
  'Downtown Business Magnet',
  'Dr. Julian Nava Learning Academy',
  'Dr. Maya Angelou Community High School',
  'Dr. Olga Mohan High School',
  'Los Angeles',
  'Dr. Richard A. Vladovic Harbor Teacher Preparation Academy',
  'Duarte High School',
  'Eagle Rock High School',
  'Eagle Rock Middle School',
  'Early College Academy for Leaders and Scholars',
  'East College Prep',
  'East Los Angeles College High School',
  'East Valley High School',
  'Eastside High School',
  'Edgewood High School',
  'El Camino Real Charter High School',
  'El Monte High School',
  'El Rancho High School',
  'El Segundo High School',
  'El Sereno Middle School',
  'Elizabeth Learning Center',
  'Emerson College',
  'Enterprise Middle School',
  'Environmental Charter High School (Lawndale)',
  'Environmental Charter Middle School - Inglewood',
  'Ernest S. McBride Sr. High School',
  'Esteban Torres High School',
  'Excelsior School',
  'Fairfax High School',
  'Felicitas and Gonzalo Mendez High School',
  'Ferrahian Armenian School',
  'Firebaugh High School',
  'First Lutheran High School',
  'Flintridge Preparatory School',
  'Flintridge Sacred Heart Academy',
  'Foshay Learning Center',
  'Francisco Bravo Medical Magnet High School',
  'Franklin D. Roosevelt Middle School',
  'Franklin High School',
  'Fred C. Nelles High School',
  'Whittier',
  'Frederick K.C. Price III Christian Schools',
  'Fremont High School',
  'Frida Kahlo High School',
  'Gabrielino High School',
  'Gahr',
  'Ganesha High School',
  'Gardena High School',
  'Garey High School',
  'Garfield High School',
  'George Washington Carver Middle School',
  'George Washington Preparatory High School',
  'Gertz-Ressler Academy High School',
  'Girls Academic Leadership Academy (GALA)',
  'Gladstone High School',
  'Glen A. Wilson High School',
  'Glendale Adventist Academy',
  'Glendale High School',
  'Glendora High School',
  'Golden Valley High School',
  'Gompers Middle School',
  'Granada Hills Charter High School',
  'Greenwood Academy',
  'Gretchen Whitney High School',
  'Grover Cleveland High School',
  'Hamilton High School',
  'Hancock Park Middle School',
  'Harbor Teacher Prep',
  'Harvard-Westlake School',
  'Hawthorne High School',
  'Hawthorne Math and Science Academy',
  'Helen Bernstein High School',
  'Herbert Hoover High School',
  'Heritage College-Ready Academy High School',
  'Hesperia High School',
  'Highland High School',
  'Hilda Solis Academy',
  'Hillcrest Christian School',
  'Hollywood High School',
  'Holy Family High School',
  'Horace Mann',
  'Horace Mann UCLA Community School',
  'Horizon Academy',
  'Hubert Howe Bancroft Middle School',
  'Huntington Park College-Ready High School',
  'Huntington Park High School',
  'Huntington Park Middle School',
  'ICEF View Park High School',
  'Immaculate Heart High School',
  'Inglewood High School',
  'Intellectual Virtues Academy - Long Beach',
  'International Polytechnic High School',
  'Pomona',
  'International Studies Learning Center',
  'Jack B. Clarke High School',
  'Norwalk',
  'James A. Garfield High School',
  'James Monroe High School',
  'Jefferson HS',
  'John A. Rowland High School',
  'John Burroughs High School',
  'John Burroughs Middle School',
  'John F. Kennedy High School',
  'John Glenn High School',
  'John H. Liechty Middle School',
  'John Marshall High School',
  'John Marshall HS',
  'Johnnie Cochran Junior Middle School',
  'Jordan High School',
  'Jordan High School',
  'Long Beach',
  'Judson International School',
  'Eagle Rock',
  'Junipero Serra High School',
  'Katherine Johnson STEM Academy',
  'King Drew Medical Magnet',
  'KIPP Academy of Opportunity',
  'KIPP Scholar Academy',
  'Kurt T. Shery High School',
  'Torrance',
  'La CaÒada High School',
  'La CaÒada Flintridge',
  'La Mirada High School',
  'La Mirada',
  'La Salle High School',
  'Pasadena',
  'La Serna High School',
  'Whittier',
  'Lafayette Park Middle School',
  'Lakewood High School',
  'Lancaster High School',
  'Lancaster',
  'Langston Hughes Academy',
  'LAUSD Kennedy',
  'Lawndale High School',
  'Lawndale',
  'Legacy High School',
  'Leuzinger High School',
  'Lawndale',
  'Lighthouse Christian Academy',
  'Santa Monica',
  'Linda Esperanza Marquez High School',
  'Linda Marquez Applied Medicine',
  'Linda Marquez Libra',
  'Linda Marquez Social Justice',
  'Little Citizens',
  'Littlerock High School',
  'Littlerock',
  'Locke High School',
  'Long Beach Polytechnic High School',
  'Long Beach Polytechnic High School',
  'Long Beach',
  'Los Altos High School',
  'Hacienda Heights',
  'Los Angeles Academy Middle School',
  'Los Angeles Adventist Academy',
  'Los Angeles',
  'Los Angeles Baptist High School',
  'North Hills',
  'Los Angeles Center for Enriched Studies (LACES)',
  'Los Angeles Complex Science Academy',
  'Los Angeles',
  'Los Angeles County High School for the Arts',
  'Los Angeles',
  'Los Angeles High School',
  'Los Angeles High School for the Arts',
  'Los Angeles Lutheran High School',
  'Sylmar',
  'Louisville High School',
  'Woodland Hills',
  'Loyola High School',
  'Harvard Heights',
  'Luther Burbank Middle School',
  'Lutheran High School',
  'La Verne',
  'LycÈe FranÁais de Los Angeles',
  'Rancho Park ñ Palms',
  'Lynwood High School',
  'MACES',
  'Magnolia Public Schools',
  'Los Angeles Area',
  'Magnolia Science Academy - Bell',
  'Magnolia Science Academy - Carson',
  'Magnolia Science Academy - Palms',
  'Magnolia Science Academy - Venice',
  'Magnolia Science Academy 7',
  'Magnolia Science Academy-1 Reseda',
  'Magnolia Science Academy-2 Valley',
  'Magnolia Science Academy-3 Carson',
  'Magnolia Science Academy-4 Venice',
  'Magnolia Science Academy-5 Los Lobos',
  'Magnolia Science Academy-6 Palms',
  'Magnolia Science Academy-8 Bell',
  'Malibu High School',
  'Malibu',
  'Manual Arts High School',
  'Maranatha High School',
  'Pasadena',
  'Mark Keppel High School',
  'Mark Keppel High School',
  'Monterey Park',
  'Mark Twain Middle School',
  'Markham Middle School',
  'Marlborough School',
  'Hancock Park',
  'Marshall Fundamental Secondary School',
  'Pasadena',
  'Mary McLeod Bethune Middle School',
  'Mary Star of the Sea High School',
  'San Pedro',
  'Marymount High School',
  'Bel-Air',
  'Mayfair High School',
  'Lakewood',
  'Mayfield Senior School of the Holy Child Jesus',
  'Pasadena',
  'Maywood Center for Enriched Studies',
  'Mervyn M. Dymally High School',
  'Middle College High School',
  'Miguel Contreras Learning Complex',
  'Millikan High School',
  'Long Beach',
  'Mira Costa High School',
  'Manhattan Beach',
  'Mission View Public Charter',
  'Gardena',
  'Mission View Public Charter',
  'Inglewood',
  'Mission View Public Charter',
  'Los Angeles',
  'Mission View Public Charter',
  'North Hills',
  'Mission View Public Charter',
  'Pacoima',
  'Mission View Public Charter',
  'Panorama City',
  'Mission View Public Charter',
  'San Fernando',
  'Mission View Public Charter',
  'Santa Clarita (2 locations)',
  'Monroe High School',
  'Monroe Middle School',
  'Monrovia High School',
  'Monrovia',
  'Montclair College Preparatory School',
  'Van Nuys',
  'Montebello High School',
  'Montebello',
  'Monterey High School',
  'Burbank',
  'Montrose Charter Academy',
  'Morningside High School',
  'Inglewood',
  'Mountain View High School',
  'El Monte',
  'Muir High School',
  'Pasadena',
  'Muir Middle School',
  'Narbonne High School',
  'Nava College Preparatory Academy',
  'New Designs Charter Schools - Watts',
  'New Designs High School',
  'New Roads School',
  'Santa Monica',
  'New West Charter School',
  'Newmark Middle School',
  'Nimitz Middle School',
  'Nogales High School',
  'La Puente',
  'North High School',
  'Torrance',
  'North Hollywood High School',
  'North Park Continuation School',
  'North Torrance HS',
  'Northeast Los Angeles High School',
  'Northview High School',
  'Covina',
  'Norwalk High School',
  'Norwalk',
  'Notre Dame Academy',
  'Rancho Park',
  'Notre Dame High School',
  'Sherman Oaks',
  'Oaks Christian School',
  'Westlake Village',
  'Oakwood School',
  'North Hollywood',
  'Odyssey STEM Academy',
  'Olympic High School',
  'Santa Monica',
  'Orchard Academies 2B',
  'Orthopaedic Medical Magnet High School',
  'Orville Wright',
  'Oscar De La Hoya Animo Charter High School',
  'Oxford School',
  'Rowland Heights',
  'Pacific Avenue Middle School',
  'Pacific Christian on the Hill',
  'Los Angeles',
  'Pacific Learning Center Charter',
  'Long Beach',
  'Pacific Lutheran High School',
  'Torrance',
  'Pacifica Christian High School',
  'Santa Monica',
  'Palisades Charter High School',
  'Palmdale High School',
  'Palmdale',
  'Palomares High School',
  'Pomona',
  'Palos Verdes High School',
  'Palos Verdes Peninsula High School',
  'Rolling Hills Estates',
  'Para Los NiÒos Middle School',
  'Paraclete High School',
  'Lancaster',
  'Paramount High School Academy',
  'Lakewood',
  'Paramount Park Middle School',
  'Parker Middle School',
  'Pasadena High School',
  'Pasadena',
  'Pathway High School',
  'Lynwood',
  'Pete Knight High School',
  'Palmdale',
  'Philippine Los Angeles High School',
  'Inglewood',
  'Phineas Banning High School',
  'Pilgrim School',
  'Los Angeles',
  'Pioneer High School',
  'Whittier',
  'Polytechnic High School',
  'Polytechnic School',
  'Pasadena',
  'Pomona Catholic High School',
  'previously Holy Name Academy',
  'Pomona High School',
  'Pomona',
  'Port of Los Angeles High School',
  'Promise Academy',
  'Long Beach',
  'Providence High School',
  'Burbank',
  'PUC Excel Charter Academy',
  'Puente Hills High School',
  'La Puente',
  'Quartz Hill High School',
  'Quartz Hill',
  'Queen Anne High School',
  'R. Rex Parris Continuation High School',
  'Palmdale',
  'Ramona Convent Secondary School',
  'Alhambra',
  'Rancho Del Mar High School',
  'Rolling Hills',
  'Rancho Dominguez',
  'Rancho Dominguez Preparatory School',
  'Redeemer Christian Academy',
  'Redondo Union High School',
  'Redondo Beach',
  'Renaissance Academy',
  'Altadena',
  'Renaissance High School for the Arts',
  'Resolute Academy',
  'Revere Middle School',
  'RFK Global Leadership',
  'RFK New Open World',
  'RFK UCLA Community School',
  'Ribet Academy',
  'Glassell Park',
  'Richard Gahr High School',
  'Cerritos',
  'Richard Merkin Middle Academy',
  'Los Angeles',
  'Ridgecrest Middle School',
  'Ridgeway Middle School',
  'Rio Hondo Preparatory School',
  'Arcadia',
  'Robert A Millikan HS',
  'Robert A. Millikan High School',
  'Robert F. Kennedy Community Schools',
  'Robert F. Kennedy Community Schools (Middle School)',
  'Robert Fulton College Preparatory School',
  'Robert H. Lewis Middle School',
  'Ron Hockwalt Academies',
  'Walnut',
  'Roosevelt Middle School',
  'Rose and Alex Pilibos Armenian School',
  'Hollywood',
  'Rosemead High School',
  'Rosemead',
  'Rosewood Middle School',
  'Sacred Heart High School',
  'Lincoln Heights',
  'Sal Castro Middle School',
  'Samuel Gompers Middle School',
  'San Dimas High School',
  'San Dimas',
  'San Gabriel Adventist Academy',
  'San Gabriel',
  'San Gabriel High School',
  'San Gabriel',
  'San Gabriel Mission High School',
  'San Gabriel',
  'San Marino High School',
  'San Marino',
  'San Pedro High School',
  'Santa Clarita Christian School',
  'Santa Clarita',
  'Santa Fe High School',
  'Santa Fe Springs',
  'Santa Monica High School',
  'Santana High School',
  'Rowland Heights',
  'Santee High School',
  'Saugus High School',
  'Santa Clarita',
  'School of Business and Tourism',
  'Schurr High School',
  'Montebello',
  'Science Academy STEM Magnet',
  'Sequoyah School',
  'Pasadena',
  'Sierra Canyon School',
  'Chatsworth',
  'Sierra Vista High School',
  'Baldwin Park',
  'Sierra Vista Middle School',
  'Soledad Enrichment Action Charter School',
  'Los Angeles',
  'Somerset High School',
  'Bellflower',
  'Sonia Sotomayor High School',
  'South Bay Lutheran High School',
  'Inglewood',
  'South East High School',
  'South El Monte High School',
  'South El Monte',
  'South Gate High School',
  'South High School',
  'Torrance',
  'South Hills High School',
  'West Covina',
  'South LA College Prep',
  'South Pasadena High School',
  'South Pasadena',
  'Southeast Academy Military and Law Enforcement High School',
  'Norwalk',
  'Southwest College Prep',
  'St Bernard High School',
  'St. Agnes',
  'St. Agnes High School',
  'St. Anthony High School',
  'Long Beach',
  'St. Bernard High School',
  'St. Bernard High School',
  'Playa del Rey',
  'St. Francis High School',
  'La CaÒada Flintridge',
  'St. Genevieve High School',
  'Panorama City',
  'St. John Bosco High School',
  'Bellflower',
  'St. Joseph High School',
  'Lakewood',
  "St. Lucy's Priory High School",
  'Glendora',
  "St. Mary's Academy",
  'Inglewood',
  'St. Monica Academy',
  'Pasadena',
  'St. Monica High School',
  'Santa Monica',
  'St. Paul High School',
  'Santa Fe Springs',
  'St. Pius X - St. Matthias Academy',
  'Downey',
  'St. Vincent',
  'St. Vincent High School',
  'STAR Prep Academy',
  'Culver City',
  'STEM3 Academy',
  'Valley Glen',
  'Stephen White',
  'Stephen White High School',
  'Stevenson Middle School',
  'Susan Miller Dorsey High School',
  'Synergy Kinetic Academy (LAUSD)',
  'Synergy Quantum Academy',
  'TCA Arshag Dickranian Armenian School',
  'Hollywood',
  'Temple City High School',
  'Temple City',
  'The School of Arts and Enterprise',
  'The Waverly School',
  'Pasadena',
  'The Webb Schools',
  'Claremont',
  'Thomas A. Edison Middle School',
  'Thomas Jefferson High School',
  'Thomas Jefferson Middle School',
  'Thomas Starr King Middle School',
  'Thurgood Marshall Charter Middle (LAUSD)',
  'Thurgood Marshall Middle School',
  'Torrance High School',
  'Torrance',
  'Tracy High School',
  'Cerritos',
  'Trinity Lutheran Junior/Senior School',
  'Reseda',
  'Triumph Academy (LAUSD)',
  'Ulysses S. Grant High School',
  'University High School',
  'University Pathways Medical Magnet',
  'University Prep Value',
  'USC Esperanza College Prep',
  'USC Hybrid High College Prep',
  'Valencia High School',
  'Santa Clarita',
  'Valley Alternative High School',
  'La Puente',
  'Valley Christian High School',
  'Cerritos',
  'Valor Academy Charter (LAUSD)',
  'Van Nuys Middle School',
  'Vasquez High School',
  'Acton',
  'Vaughn Next Century Learning Center (Middle School)',
  'Verbum Dei Jesuit High School',
  'Verdugo Hills High School',
  'View Park Preparatory Accelerated Charter Middle School',
  'Viewpoint School',
  'Calabasas',
  'Village Academy High School at Indian Hill',
  'Pomona',
  'Village Christian School',
  'Sun Valley',
  'Village Glen High School',
  'Sherman Oaks',
  'Virtual Academy (LAUSD)',
  'Vista High School',
  'Vistamar School',
  'El Segundo',
  'Wallis Annenberg High School',
  'Walnut High School',
  'Walnut',
  'Walton Middle School',
  'Warren High School',
  'Downey',
  'Washington High School',
  'Watts Learning Center',
  'West Adams Preparatory Academy',
  'West Covina High School',
  'West Covina',
  'West High School',
  'Torrance',
  'West Ranch High School',
  'Stevenson Ranch',
  'Westchester Enriched Science Magnet',
  'Westside Leadership Academy',
  'Westwood Charter Elementary',
  'Whaley Middle School',
  'Whittier High School',
  'Whittier',
  'Wild Wood High School',
  'Los Angeles',
  "Wilder's Preparatory Charter Middle (Inglewood)",
  'Wildwood School',
  'Los Angeles',
  'Wiley College High School',
  'William Howard Taft High School',
  'William Howard Taft Middle School',
  'William Jefferson Clinton Middle School',
  'William S. Hart High School',
  'Santa Clarita',
  'William Workman High School',
  'City of Industry',
  'Wilmington Prep Academy',
  'Wilson Classical High School',
  'Long Beach',
  'Wilson High School',
  'Windward School',
  'Mar Vista',
  'Wish Charter Middle School',
  'Woodland Hills Academy',
  'Young Oak Kim Academy',
  'Youth Opportunities',
  'Yucca Valley High School',
  'Zamboni High School',
];

const SCHOOL_LIST = RAW_SCHOOL_LIST.filter(
  (value, index, self) => self.indexOf(value) === index,
);

export default SCHOOL_LIST;
