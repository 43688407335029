import React from 'react';
import styled from 'styled-components';
import Loader from 'components/Elements/Loader/Loader';
import ApplicationContentContainer from 'components/Layout/ApplicationContentContainer';
import { UserSession } from 'types';
import { Outlet } from 'react-router-dom';
import { SclaApplication } from '../types';
import useSclaApplication from '../hooks/useSclaApplication';

const PageContainer = styled.div`
  padding: 1rem 1rem;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media (min-width: 992px){
    padding: 1rem 2rem;
  }
`;

const AlertPageContainer = styled.div`
  padding: 3rem 2rem 1rem 2rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  display: flex;
`;

export interface SclaApplicationOutletContext {
  application: SclaApplication;
  setApplication: (app: SclaApplication) => void;
}

export default function SclaAppLayout({
  user,
}: {
  user: UserSession;
}) {
  const {
    application,
    isDoneLoading,
    setApplication,
  } = useSclaApplication(user._id, '2025');

  const sections: any[] = [];
  if (!isDoneLoading) {
    return <Loader isVisible fullscreen={false} theme="light" />;
  }

  if (application === null) {
    return (
      <AlertPageContainer>
        <p>start app</p>
      </AlertPageContainer>
    );
  }

  return (
    <PageContainer>
      <ApplicationContentContainer links={sections}>
        <Outlet context={{ application, setApplication }} />
      </ApplicationContentContainer>
    </PageContainer>
  );
}
