import { useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import {
  getFormValidationStatusAsync,
  scrollToFormErr,
} from 'utils/form.util';
import formValidation from 'constants/validation';
import { FormField } from 'components/Form/CustomForm';
import { PICKER_ETHNICITY, PICKER_GRADE } from 'constants/picker';
import SCHOOL_LIST from 'constants/schools';
import recordInterests from '../api/recordInterests';

export interface IInterestsForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  schoolName: string;
  ethnicity: string;
  ethnicityOther: string;
  gradeLevel: string;
  email: string;
  cohortYear: string;
}

const { errorMessages: err } = formValidation;

// Extract schema types for form validation
const { StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  firstName: StringType().isRequired(err.firstName),
  lastName: StringType().isRequired(err.lastName),
  phoneNumber: StringType(),
  schoolName: StringType().isRequired(err.school),
  gradeLevel: StringType().isRequired(err.grade),
  ethnicity: StringType().isRequired(err.ethnicity),
  email: StringType().isRequired(err.email).isEmail(err.email),
});

const INIT_FORM: IInterestsForm = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  schoolName: '',
  ethnicity: '',
  gradeLevel: '',
  email: '',
  ethnicityOther: '',
  cohortYear: '2025'
};

const fields: FormField[] = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    size: 'lg',
    data: null,
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    size: 'lg',
    data: null,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    size: 'lg',
    data: null,
  },
  {
    name: 'phoneNumber',
    type: 'phoneNumber',
    label: 'Phone Number',
    size: 'lg',
    data: null,
  },
  {
    name: 'schoolName',
    type: 'autoComplete',
    label: 'School Name',
    size: 'lg',
    data: SCHOOL_LIST,
  },

  {
    name: 'gradeLevel',
    type: 'select',
    label: 'Grade Level',
    size: 'lg',
    data: {
      otherLabel: 'Enter your grade',
      picker: PICKER_GRADE,
      searchable: false,
    },
  },

  {
    name: 'ethnicity',
    type: 'select',
    label: 'Ethnicity',
    size: 'lg',
    data: {
      otherLabel: 'Enter your ethnicity',
      picker: PICKER_ETHNICITY,
      searchable: false,
    },
  },
];

export default function useInterests() {
  const [formValue, setFormValue] = useState<IInterestsForm>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process
  const [isComplete, setIsComplete] = useState(false);

  /**
   * Makes server request to validate user credentials.
   * Users with valid credentials are authenticated and
   * redirect to the authenticated routes
   */
  const onSubmit = async () => {
    try {
      const isValid = await getFormValidationStatusAsync(
        model.check(formValue),
      );
      // verify that form is valid
      if (isValid) {
        setIsLoading(true);

        await recordInterests(formValue);

        // show spinner
        setIsLoading(false);
        setIsComplete(true);
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: any) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    isComplete,
    onChange,
    onSubmit,
  };
}
